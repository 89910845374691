import React, { useRef, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import StyledButton from "../../Components/StyledComponents/StyledButton";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import { Formik, Form } from "formik";
import FormikInputField from "../../Components/Formik/FormikInputField";
import AddIcon from "../../Components/Svgicons/AddIcon";
import * as Yup from "yup";
import UploadIcon from "../../Components/Svgicons/UploadIcon";
import { usePostNewAdminMutation } from "../../Services/Admin Api/adminDashboardApi/adminWorkplaceApi/adminWPApiSlice";
import CrossIcon from "../../Components/Svgicons/CrossIcon";
import { toast } from "react-toastify";
import { fonts, getIconByFileExtension } from "../../Services/Utils/helper";
import { useTranslation } from "react-i18next";
import { useAdminImagePreSignedUrlMutation, useUpdateAdminMutation } from "../../Services/Admin Api/adminDashboardApi/clientWorkplaceApi/adminApiSlice";
import UpdateIcon from "../../Components/Svgicons/UpdateIcon";
import { StyledIconBg } from "../../Components/StyledComponents/CommonStyled";

const AddAdminModal = ({
  show,
  onHide,
  refetchAdmin,
  isEditAdmin,
  setIsEditAdmin,
}) => {
  const { t } = useTranslation();
  const [attachments, setAttachment] = useState({});
  const [progress, setProgress] = useState(false);
  const [file, setFile] = useState(null);
  const [getAdminImagePreSignedUrl] =
    useAdminImagePreSignedUrlMutation();

  const [addAdmin, { isLoading: loadAdmins }] = usePostNewAdminMutation();
  const [updateAdmin, { isLoading: updateAdminloading }] =
    useUpdateAdminMutation();
  const fileInputRef = useRef(null);
  const handleButtonClick = (evt) => {
    fileInputRef.current.click();
  };

  const handleAttachment = (evt) => {
    const file = evt.target.files[0];
    setAttachment(file);
  };

  const handleSubmit = (values) => {
    let payloadData = {
      ...values,
      profileImage: file ? file?.key : undefined
    }

    let val = isEditAdmin?.state
      ? { payloadData, id: isEditAdmin?.data?.id }
      : payloadData;

    const call = isEditAdmin?.state ? updateAdmin : addAdmin;

    call(val).then((Resp) => {

      onHide();
      if (Resp.error?.status !== 500) {
        toast.success(
          isEditAdmin?.state
            ? t("admin:AdminUpdatedSuccessfully")
            : t("admin:AdminAddedSuccessfully")
        );
        setIsEditAdmin({ data: {}, state: false });
        refetchAdmin();
      }

    }).catch((error) => {
      // Error case
      console.log(error, 'error');
      toast.error(t("admin:AnErrorOccurred")); // Show error toast
    });




  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        t('common:invalidEmail')
      )
      .required(t('common:emailRequired')),
    firstName: Yup.string().required(t('common:firstNameRequired')),
    lastName: Yup.string().required(t('common:lastNameRequired')),
    phoneNumber: Yup.string()
      .required(t("common:phoneRequired"))
      .matches(/^\d+$/, t("common:phoneRequiredDigit")),
    personalNumber: Yup.string()
      .matches(/^\d{12}$/, t('common:personalNumberDigitRequired'))
      .required(t('common:personalNumberRequired')),
  });

  const handleUploadFile = async (e) => {
    const file = e?.target?.files[0];
    setFile({ file });
    setProgress(true);
    if (!file) return;
    const {
      data: { response: responseData },
    } = await getAdminImagePreSignedUrl({
      fileName: file?.name,
      fileType: file?.type,
    });
    if (!responseData?.url) {
      return;
    }
    await fetch(`${responseData?.url}`, {
      method: responseData?.method,
      headers: {
        Accept: "application/json",
        "Content-Type": file?.type,
      },
      body: file,

    });
    setFile({ file, key: responseData?.key });
    setProgress(false);
  };

  return (
    <Modal show={show} centered onHide={onHide} size={"lg"} >
      <Modal.Body className="d-flex flex-column bg-body rounded p-3 p-sm-5">
        <div className="d-flex align-item-center justify-content-between">
          <div className="d-flex flex-column align-items-center justify-content-center w-100">
            <StyledText
              as="h1"
              $fontSize="20px"
              $fontWeight={600}
              color={theme.secondary}
            >
              {isEditAdmin?.state
                ? t("admin:EditAdmin")
                : t("admin:titleModalAddAdmin")}
            </StyledText>
            <StyledText
              as="p"
              $fontSize="14px"
              $fontWeight={400}
              color={theme.gray}
              className="mb-0"
            >
              {isEditAdmin?.state
                ? t("admin:EditAdminDesc")
                : t("admin:descModalAddAdmin")}
            </StyledText>
          </div>
          <div onClick={onHide} className="cursor-pointer d-block d-md-none">
            <CrossIcon />
          </div>
        </div>
        <Formik
          initialValues={{
            email: isEditAdmin?.data?.email || "",
            firstName: isEditAdmin?.data?.firstName || "",
            lastName: isEditAdmin?.data?.lastName || "",
            phoneNumber: isEditAdmin?.data?.phoneNumber || "",
            personalNumber: isEditAdmin?.data?.personalNumber || "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, values }) => (
            <Form>
              <Row>

              <div className='mt-4 col-md-6'>
                  <div className='d-flex align-items-baseline mb-1'>

                    <StyledText
                      as='label'
                      htmlFor='email'
                      $fontWeight={600}
                      $fontSize="14px"
                      $fontFamily={fonts.raleway}
                      color={theme.headingTextColor}
                      className='ml-1'
                    >
                      {t("common:textEmail")}
                    </StyledText>
                    <StyledText
                      as='label'
                      htmlFor='email'
                      color={theme.danger}
                      className='mt-2'
                    >
                      *
                    </StyledText>
                  </div>
                  <FormikInputField
                    name='email'
                    id='email'
                    placeholder={t("common:enterEmail")}
                  />
                </div>


                <div className='mt-4 col-md-6'>
                  <div className='d-flex align-items-baseline mb-1'>

                    <StyledText
                      as='label'
                      htmlFor='firstName'
                      $fontWeight={600}
                      $fontSize="14px"
                      $fontFamily={fonts.raleway}
                      color={theme.headingTextColor}
                      className='ml-1'
                    >
                      {t("common:textFirstName")}
                    </StyledText>
                    <StyledText
                      as='label'
                      htmlFor='firstName'
                      color={theme.danger}
                      className='mt-2'
                    >
                      *
                    </StyledText>
                  </div>
                  <FormikInputField
                    name='firstName'
                    id='firstName'
                    placeholder={t("common:enterFirstName")}
                  />
                </div>

                <div className='mt-4 col-md-6'>
                  <div className='d-flex align-items-baseline mb-1'>

                    <StyledText
                      as='label'
                      htmlFor='lastName'
                      $fontWeight={600}
                      $fontSize="14px"
                      $fontFamily={fonts.raleway}
                      color={theme.headingTextColor}
                      className='ml-1'
                    >
                      {t("common:textLastName")}
                    </StyledText>
                    <StyledText
                      as='label'
                      htmlFor='lastName'
                      color={theme.danger}
                      className='mt-2'
                    >
                      *
                    </StyledText>
                  </div>
                  <FormikInputField
                    name='lastName'
                    id='lastName'
                    placeholder={t("common:enterLastName")}
                  />
                </div>

               

                <div className='mt-4 col-md-6'>
                  <div className='d-flex align-items-baseline mb-1'>

                    <StyledText
                      as='label'
                      htmlFor='phoneNumber'
                      $fontWeight={600}
                      $fontSize="14px"
                      $fontFamily={fonts.raleway}
                      color={theme.headingTextColor}
                      className='ml-1'
                    >
                      {t("common:textPhoneNumber")}
                    </StyledText>
                    <StyledText
                      as='label'
                      htmlFor='phoneNumber'
                      color={theme.danger}
                      className='mt-2'
                    >
                      *
                    </StyledText>
                  </div>
                  <FormikInputField
                    name='phoneNumber'
                    id='phoneNumber'
                    placeholder={t("common:enterPhoneNo")}
                  />
                </div>

                <div className='mt-4 col-12'>
                  <div className='d-flex align-items-baseline mb-1'>

                    <StyledText
                      as='label'
                      htmlFor='personalNumber'
                      $fontWeight={600}
                      $fontSize="14px"
                      $fontFamily={fonts.raleway}
                      color={theme.headingTextColor}
                      className='ml-1'
                    >
                      {t("common:textPersonalNoFull")}
                    </StyledText>
                    <StyledText
                      as='label'
                      htmlFor='personalNumber'
                      color={theme.danger}
                      className='mt-2'
                    >
                      *
                    </StyledText>
                  </div>
                  <FormikInputField
                    name='personalNumber'
                    id='personalNumber'
                    placeholder={t("common:enterPersonalNo")}
                  />
                </div>

                <div className="mt-4 col-12">
                  <div className="mb-2">
                    <StyledText $fontSize="14px" $fontWeight={600} $fontFamily={fonts.raleway}>
                      {t("common:textUploadProfileImage")}
                    </StyledText>
                  </div>
                  {file?.file?.name ? (
                    <div className="d-flex align-items-center justify-content-between bg-light-gray p-2 rounded bg-opacity-50 mb-3">
                      <div className="d-flex align-items-center gap-3">
                        {getIconByFileExtension(file?.file?.name)}
                        {file?.file?.name}
                      </div>
                      {progress ? <div className="spinner-border spinner-border-sm" /> :
                        <div
                          className="bg-secondary bg-opacity-25 rounded cursor-pointer"
                          onClick={() => {
                            setFile(null);
                            fileInputRef.current.value = null;
                          }}
                        >
                          <CrossIcon />
                        </div>}
                    </div>
                  ) : null}
                  <StyledButton
                    icon={<StyledIconBg height='40px' width='40px' color={theme.white}><UploadIcon /></StyledIconBg>}
                    variant="snowWhite"
                    borderColor={theme.lavenderGray}
                    height={81}
                    className={`w-100 py-5 cursor-pointer ${file?.file ? 'disabled' : ''}`}
                    onClick={handleButtonClick}
                  >
                    <div className="d-flex flex-column align-items-start">
                      <input
                        ref={fileInputRef}
                        type="file"
                        id="attachments"
                        name="attachments"
                        accept="image/png, image/jpeg"
                        hidden
                        onChange={(evt) => handleUploadFile(evt)}
                      />
                      <StyledText
                        $fontSize="14px"
                        $fontWeight={600}
                        color={theme.secondary}
                        $fontFamily={fonts.raleway}
                      // className="d-none d-sm-block"
                      >
                        {t("common:textBtnUploadImg1")}
                      </StyledText>
                      <StyledText
                        $fontSize="12px"
                        $fontWeight={500}
                        $fontFamily={fonts.raleway}
                        color={theme.gray}
                      >
                        {t("admin:supportedFormats")}
                      </StyledText>
                    </div>
                  </StyledButton>
                </div>

              </Row>
              <StyledButton
                icon={
                  isEditAdmin?.state ? (
                    <UpdateIcon className="me-2" />
                  ) : (
                    <AddIcon />
                  )
                }
                className={`mt-5 py-2 text-white d-inline-flex w-100 ${progress ? 'disabled' : ''}`}
                type="submit"
                loading={loadAdmins || updateAdminloading}
                disabled={loadAdmins || updateAdminloading}
              >
                {isEditAdmin?.state
                  ? t("admin:UpdateAdmin")
                  : t("admin:btnLblAddAdmin")}
              </StyledButton>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};



export default AddAdminModal;
