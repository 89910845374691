import React from 'react'
import { DocumentCard, StyledBadge } from './CommonStyled'
import StyledText from './StyledText'
import theme from '../../Services/Utils/themes'
import { capitalizeFirstLetter, DefaulImage, fonts, getIconByFileExtension, handleErrorImg } from '../../Services/Utils/helper'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import StyledImage from './StyledImage'
import UserIcon from '../Svgicons/UserIcon'
import EmailBox from '../Svgicons/EmailBox'
import PhoneIcon from '../Svgicons/PhoneIcon'

const StyledUserCard = ({ options, data }) => {
    
    const { t } = useTranslation();
    return (
        <DocumentCard className='rounded-4'>

            <div className='py-4 my-2'>
                <div className='d-flex flex-column align-items-center' >
                    <StyledImage height={'100px'} width={'100px'}>
                        <img src={data?.signedUrl || DefaulImage.image } onError={handleErrorImg} alt="adminImage" style={{objectFit: 'cover'}}/>
                    </StyledImage>

                    <StyledText $fontWeight={600} $fontSize="14px" color={theme.secondary} $fontFamily={fonts.raleway} className='mt-3 mb-2'>
                        {`${capitalizeFirstLetter(data?.firstName)} ${data?.lastName}`}
                    </StyledText>

                   <div className='d-flex align-items-center gap-1'>
                    <div>
                        <UserIcon color={theme.cyanBlue} size={14}/>
                    </div>
                   <StyledText $fontWeight={500} $fontSize="14px" color={theme.stormDust} $fontFamily={fonts.quicksand}>
                        {data?.personalNumber}
                    </StyledText>
                   </div>

                   <div className='d-flex align-items-center gap-1 py-1'>
                   <div>
                        <EmailBox/>
                    </div>
                   <StyledText $fontWeight={500} $fontSize="14px" color={theme.stormDust} $fontFamily={fonts.quicksand}>
                        {data?.email}
                    </StyledText>
                   </div>
                   <div className='d-flex align-items-center gap-1'>
                   <div>
                        <PhoneIcon/>
                    </div>
                   <StyledText $fontWeight={500} $fontSize="14px" color={theme.stormDust} $fontFamily={fonts.quicksand}>
                        {data?.phoneNumber}
                    </StyledText>
                   </div>
                </div>

            </div>
        </DocumentCard>
    )
};


export default StyledUserCard