import React from 'react';
import theme from '../../Services/Utils/themes';
import AppLogo from "../../Assets/Images/appLogo.png";
import StyledImage from '../../Components/StyledComponents/StyledImage';
import { useGetUserProfileQuery } from '../../Services/DashboardApi/dashboardApiSlice';
import { DefaulImage, handleErrorImg, USERTYPE } from '../../Services/Utils/helper';
import { ContentSection, ProfileSection, SidebarContainer, SidebarFooter, SidebarHeader, StyledHr, StyledNavLink } from '../../CommonComponents/SidebarStyling';
import StyledText from '../../Components/StyledComponents/StyledText';
import ConversationIcon from '../../Components/Svgicons/ConversationIcon';
import SharedDocumentIcon from '../../Components/Svgicons/SharedDocumentIcon';
import ResourcesIcon from '../../Components/Svgicons/ResourcesIcon';
import DocumentPostingIcon from '../../Components/Svgicons/DocumentPostingIcon';
import StyledButton from '../../Components/StyledComponents/StyledButton';
import LogoutIcon from '../../Components/Svgicons/LogoutIcon';
import { useDispatch } from 'react-redux';
import { removeCredentials } from '../../Services/Auth/authSlice';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import EditIcon from '../../Components/Svgicons/EditIcon';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../Services/Hooks/useAuth';
import { apiSlice } from '../../Services/ApiSlice/apiSlice';
import NomineeDocumentIcon from '../../Components/Svgicons/NomineeDocumentIcon';
import UserIcon from '../../Components/Svgicons/UserIcon';





const Sidebar = ({ isOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const {
    data: getUserDetail
  } = useGetUserProfileQuery();


  const MenuItems = [
    {
      name: t('client:conversationHistory'),
      to: 'dashboard',
      icon: <ConversationIcon />
    },
    {
      name: t('client:sharedDocument'),
      to: 'shared-document',
      icon: <SharedDocumentIcon />

    },

{
      name: t('client:nomineeDocument'),
      to: 'nominee-document',
      icon: <NomineeDocumentIcon />
    },
    {
      name: t('common:resources'),
      to: 'resources',
      icon: <ResourcesIcon />

    },
    {
      name: t('common:clientDocumentPosting'),
      to: 'document-posting',
      icon: <DocumentPostingIcon />

    },
    {
      name: t('common:team'),
      to: 'team',
      icon: <UserIcon />

    }
  ];

  return (
    <SidebarContainer isOpen={isOpen}>

      <SidebarHeader>
        <img src={AppLogo} alt="appLogo" height={45} />
      </SidebarHeader>

      <ProfileSection>
        <div className='mb-3 position-relative'>
          <EditProfile onClick={() => navigate('/profile')}>
            <EditIcon color='black' size={20} />
          </EditProfile>
          <StyledImage
            height={"76px"}
            width={"76px"}
            className="border border-white rounded-pill"
          >
            <img
              src={getUserDetail?.data?.user?.signedUrl || DefaulImage.image}
              alt="userProfile"
              onError={handleErrorImg}
              style={{ objectFit: "cover" }}
            />
          </StyledImage>
        </div>
        <StyledText $fontWeight={600} $fontSize="14px" color={theme.white}>{`${getUserDetail?.data?.user?.firstName || ""} ${getUserDetail?.data?.user?.lastName || ""
          }`}</StyledText>
        <StyledText $fontWeight={400} $fontSize="12px" color={theme.white}>{getUserDetail?.data?.user?.email}</StyledText>

      </ProfileSection>

      <StyledHr />

      <ContentSection>

        {MenuItems.map((item, index) => {
          return (
            <StyledNavLink
              key={index}
              to={item?.to}
              className="px-0"
            >
              <div>{item?.icon}</div>
              <p className=" mb-0">{item?.name}</p>
            </StyledNavLink>
          );
        })}
       {getUserDetail?.data?.user?.caseDetail ? <StyledHr className='my-3' /> : null}

        { getUserDetail?.data?.user?.caseDetail ?<div className=''>
          <StyledText as='label' $fontWeight={600} $fontSize="14px" color={theme.white}>
            {t('client:myCaseDetails')}
          </StyledText>

          <StyledText as='label' $fontWeight={400} $fontSize="13px" color={theme.white} lh="1.4" className='mt-1' truncatedlines={3}>
            {getUserDetail?.data?.user?.caseDetail}
          </StyledText>
        </div> : null}
      </ContentSection>


      <SidebarFooter>
        <StyledButton variant="primary" icon={<LogoutIcon color={theme.white} />} className="w-100 mx-2" onClick={() => { dispatch(removeCredentials()); dispatch(apiSlice.util.resetApiState()) }}>
          {t('common:logout')}
        </StyledButton>
      </SidebarFooter>

    </SidebarContainer>
  );
};


export const EditProfile = styled.div`
  position: absolute;
  right: 0;
background-color: ${theme.white};
  border-radius: 50%;
  padding: 5px;
  bottom: 0;
  cursor: pointer;
  
`



export default Sidebar;
