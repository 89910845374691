import React from "react";
import { Modal, Row } from "react-bootstrap";
import StyledButton from "../../Components/StyledComponents/StyledButton";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import { Formik, Form } from "formik";
import FormikInputField from "../../Components/Formik/FormikInputField";
import AddIcon from "../../Components/Svgicons/AddIcon";
import * as Yup from "yup";
import {
  usePostNewClientMutation,
  useUpdateClientMutation,
} from "../../Services/Admin Api/adminDashboardApi/clientWorkplaceApi/adminApiSlice";
import { useTranslation } from "react-i18next";
import CrossIcon from "../../Components/Svgicons/CrossIcon";
import { toast } from "react-toastify";
import UpdateIcon from "../../Components/Svgicons/UpdateIcon";
import { svenskaLs } from "../../Services/Utils/localStorage";
import { fonts } from "../../Services/Utils/helper";

const AddClientModal = ({ show, onHide, refetchUsers, isEdit, setIsEdit }) => {
  const [addClient, { isLoading: clientLoading }] = usePostNewClientMutation();
  const [updateClient, { isLoading: updateLoading }] =
    useUpdateClientMutation();
  const { t } = useTranslation();

  const handleSubmit = (values) => {
    const call = isEdit?.state ? updateClient : addClient;
    const lang = svenskaLs.getItem("lng") || "swe";

    const updateData = isEdit?.state
      ? { ...values, id: isEdit?.data?.id }
      : values;

    updateData.trans = lang;

    call(updateData).then((resp) => {
      refetchUsers();
      onHide();
      if (resp?.error?.status === 400) {
        toast.error(
          resp?.error?.data?.message || t("admin:ClientAlreadyExist")
        );
      } else {
        toast.success(
          isEdit?.state
            ? t("admin:ClientUpdatedSuccessfully")
            : t("admin:ClientAddedSuccessfully")
        );
        setIsEdit({ data: {}, state: false });
      }
    });
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        t('common:invalidEmail')
      )
      .required(t('common:emailRequired')),

    firstName: Yup.string().required(t("common:firstNameRequired")),
    lastName: Yup.string().required(t("common:lastNameRequired")),
    phoneNumber: Yup.string()
      .required(t("common:phoneRequired"))
      .matches(/^\d+$/, t("common:phoneRequiredDigit")),
    personalNumber: Yup.string()
      .matches(/^\d{12}$/, t("common:personalNumberDigitRequired"))
      .required(t("common:personalNumberRequired")),
    country: Yup.string().required(t("common:countryRequired")),
    city: Yup.string().required(t("common:cityRequired")),
    postalCode: Yup.string().required(t("common:postalCodeRequired")),
  });
  return (
    <Modal show={show} centered onHide={onHide} size={"lg"}>
      <Modal.Body className="d-flex flex-column bg-body rounded p-3 p-sm-5">
        <div className="d-flex align-item-center justify-content-between">
          <div className="d-flex flex-column justify-content-center align-items-center w-100 pb-2">
            <StyledText
              as="h1"
              $fontSize="20px"
              $fontWeight={600}
              color={theme.secondary}
            >
              {isEdit?.state
                ? t("admin:editClient")
                : t("admin:addNewClient")}
            </StyledText>
            <StyledText
              as="p"
              $fontSize="14px"
              $fontWeight={400}
              color={theme.gray}
            >
              {isEdit?.state
                ? t("admin:detailsToUpdateClient")
                : t("admin:detailsToAddNewClient")}
            </StyledText>
          </div>
          <div onClick={onHide} className="cursor-pointer d-block d-md-none">
            <CrossIcon />{" "}
          </div>
        </div>
        <Formik
          initialValues={{
            email: isEdit?.data?.email || "",
            firstName: isEdit?.data?.firstName || "",
            lastName: isEdit?.data?.lastName || "",
            phoneNumber: isEdit?.data?.phoneNumber || "",
            personalNumber: isEdit?.data?.personalNumber || "",
            country: isEdit?.data?.country || "",
            city: isEdit?.data?.city || "",
            postalCode: isEdit?.data?.postalCode || "",
            caseDetail: isEdit?.data?.caseDetail || "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, values }) => (
            <Form>
              <div className=" border-top">

                <Row>

                <div className='mt-4 col-md-6'>
                    <div className='d-flex align-items-baseline mb-1'>

                      <StyledText
                        as='label'
                        htmlFor='email'
                        $fontWeight={600}
                        $fontSize="14px"
                        $fontFamily={fonts.raleway}
                        color={theme.headingTextColor}
                        className='ml-1'
                      >
                        {t("common:textEmail")}
                      </StyledText>
                      <StyledText
                        as='label'
                        htmlFor='email'
                        color={theme.danger}
                        className='mt-2'
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      name='email'
                      id='email'
                      placeholder={t("common:enterEmail")}
                    />
                  </div>


                  <div className='mt-4 col-md-6'>
                    <div className='d-flex align-items-baseline mb-1'>

                      <StyledText
                        as='label'
                        htmlFor='firstName'
                        $fontWeight={600}
                        $fontSize="14px"
                        $fontFamily={fonts.raleway}
                        color={theme.headingTextColor}
                        className='ml-1'
                      >
                        {t("common:textFirstName")}
                      </StyledText>
                      <StyledText
                        as='label'
                        htmlFor='firstName'
                        color={theme.danger}
                        className='mt-2'
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      name='firstName'
                      id='firstName'
                      placeholder={t("common:enterFirstName")}
                    />
                  </div>

                  <div className='mt-4 col-md-6'>
                    <div className='d-flex align-items-baseline mb-1'>

                      <StyledText
                        as='label'
                        htmlFor='lastName'
                        $fontWeight={600}
                        $fontSize="14px"
                        $fontFamily={fonts.raleway}
                        color={theme.headingTextColor}
                        className='ml-1'
                      >
                        {t("common:textLastName")}
                      </StyledText>
                      <StyledText
                        as='label'
                        htmlFor='lastName'
                        color={theme.danger}
                        className='mt-2'
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      name='lastName'
                      id='lastName'
                      placeholder={t("common:enterLastName")}
                    />
                  </div>

                 


                  <div className='mt-4 col-md-6'>
                    <div className='d-flex align-items-baseline mb-1'>

                      <StyledText
                        as='label'
                        htmlFor='phoneNumber'
                        $fontWeight={600}
                        $fontSize="14px"
                        $fontFamily={fonts.raleway}
                        color={theme.headingTextColor}
                        className='ml-1'
                      >
                        {t("common:textPhoneNumber")}
                      </StyledText>
                      <StyledText
                        as='label'
                        htmlFor='phoneNumber'
                        color={theme.danger}
                        className='mt-2'
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      name='phoneNumber'
                      id='phoneNumber'
                      placeholder={t("common:enterPhoneNo")}
                    />
                  </div>

                  <div className={`mt-4 col-md-6 ${isEdit?.state ? 'feature-pending' : ''}`}>
                    <div className='d-flex align-items-baseline mb-1'>

                      <StyledText
                        as='label'
                        htmlFor='personalNumber'
                        $fontWeight={600}
                        $fontSize="14px"
                        $fontFamily={fonts.raleway}
                        color={theme.headingTextColor}
                        className='ml-1'
                      >
                        {t("common:textPersonalNoFull")}
                      </StyledText>
                      <StyledText
                        as='label'
                        htmlFor='personalNumber'
                        color={theme.danger}
                        className='mt-2'
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      name='personalNumber'
                      id='personalNumber'
                      placeholder={t("common:enterPersonalNo")}
                    />
                  </div>

                  <div className='mt-4 col-md-6'>
                    <div className='d-flex align-items-baseline mb-1'>

                      <StyledText
                        as='label'
                        htmlFor='country'
                        $fontWeight={600}
                        $fontFamily={fonts.raleway}
                        $fontSize="14px"
                        color={theme.headingTextColor}
                        className='ml-1'
                      >
                        {t("common:textCountry")}
                      </StyledText>
                      <StyledText
                        as='label'
                        htmlFor='country'
                        color={theme.danger}
                        className='mt-2'
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      name='country'
                      id='country'
                      placeholder={t("common:enterCountry")}
                    />
                  </div>

                  <div className='mt-4 col-md-6'>
                    <div className='d-flex align-items-baseline mb-1'>

                      <StyledText
                        as='label'
                        htmlFor='city'
                        $fontFamily={fonts.raleway}
                        $fontWeight={600}
                        $fontSize="14px"
                        color={theme.headingTextColor}
                        className='ml-1'
                      >
                        {t("common:textCityState")}
                      </StyledText>
                      <StyledText
                        as='label'
                        htmlFor='city'
                        color={theme.danger}
                        className='mt-2'
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      name='city'
                      id='city'
                      placeholder={t("common:enterCityState")}
                    />
                  </div>

                  <div className='mt-4 col-md-6'>
                    <div className='d-flex align-items-baseline mb-1'>

                      <StyledText
                        as='label'
                        htmlFor='postalCode'
                        $fontWeight={600}
                        $fontSize="14px"
                        $fontFamily={fonts.raleway}
                        color={theme.headingTextColor}
                        className='ml-1'
                      >
                        {t("common:textPostalCode")}
                      </StyledText>
                      <StyledText
                        as='label'
                        htmlFor='postalCode'
                        color={theme.danger}
                        className='mt-2'
                      >
                        *
                      </StyledText>
                    </div>
                    <FormikInputField
                      name='postalCode'
                      id='postalCode'
                      placeholder={t("common:enterPostalCode")}
                    />
                  </div>

                  <div className='mt-4 col'>
                    <div className='d-flex align-items-baseline mb-1'>

                      <StyledText
                        as='label'
                        htmlFor='caseDetail'
                        $fontWeight={600}
                        $fontSize="14px"
                        $fontFamily={fonts.raleway}
                        color={theme.headingTextColor}
                        className='ml-1'
                      >
                        {t("common:textCaseDetail")}
                      </StyledText>
                    </div>
                    <FormikInputField
                      as="textarea"
                      name='caseDetail'
                      id='caseDetail'
                      rows="4"
                      placeholder={t("common:enterCaseDetails")}
                    />
                  </div>

                </Row>
              </div>
              <StyledButton
                icon={isEdit?.state ? <UpdateIcon /> : <AddIcon />}
                className="mt-3 py-2 text-white d-inline-flex w-100 mt-5"
                type="submit"
                disabled={updateLoading || clientLoading}
                loading={updateLoading || clientLoading}
              >
                <span className="d-block d-sm-none d-md-block">
                  {isEdit?.state
                    ? t("admin:updateClient")
                    : t("admin:addClient")}
                </span>
              </StyledButton>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};



export default AddClientModal;
