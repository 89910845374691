import React from 'react'
import PaginationPerPage from '../../../Components/pagination/PaginationPerPage'
import { useTranslation } from 'react-i18next';
import StyledImage from '../../../Components/StyledComponents/StyledImage';
import { DefaulImage, STATUSES, capitalizeFirstLetter, filterHtml, fonts, handleErrorImg, useWindowSize } from '../../../Services/Utils/helper';
import StyledText from '../../../Components/StyledComponents/StyledText';
import theme from '../../../Services/Utils/themes';
import FileAttachmentLable from '../../../Components/FileAttachmentLable';
import EditIcon from '../../../Components/Svgicons/EditIcon';
import DeleteIcon from '../../../Components/Svgicons/DeleteIcon';
import { StyledBadge, StyledContainer, UnreadCountCircle } from '../../../Components/StyledComponents/CommonStyled';
import ViewIcon from '../../../Components/Svgicons/ViewIcon';
import CommonTable from '../CommonTable';
import StyledTableSkeleton from '../../../Components/StyledComponents/StyledTableSkeleton';
import { svenskaLs } from '../../../Services/Utils/localStorage';
import styled from 'styled-components';
import MessageIcon from '../../../Components/Svgicons/MessageIcon';

const AdminClientTable = ({ clientDetails, handlePerPageChange, handlePageChange, setaddClientModalOpen, setIsEdit, setDeleteData, setUId, loadClients, refetchUsers }) => {
    const { t } = useTranslation();
    const isSmallScreen = useWindowSize();

    const column = [
        {
            id: 1,
            path: "title",
            label: (
                <StyledText as="label" $fontFamily={fonts.raleway} $fontWeight={600} $fontSize="13px" truncateWidth="186px">
                    {t("admin:titleTblClientListCol1")}
                </StyledText>
            ),
            isChecked: true,
            alignBody: "text-left align-middle fw-bold px-3 px-md-5 py-3",
            alignHeader: "text-left align-middle cursor-pointer px-3 px-md-5 py-3",
            content: (docs, index) => (
                <div className="d-flex align-items-center gap-2">
                    <div>
                        <StyledImage height={"40px"} width={"40px"} className=" rounded-pill">
                            <img
                                src={docs?.signedUrl || DefaulImage.image}
                                alt="img"
                                onError={handleErrorImg}
                                style={{ objectFit: "cover" }}
                            />
                        </StyledImage>
                    </div>
                    <StyledText
                        $fontWeight={600}
                        $fontSize="13px"
                        color={theme.secondary}
                        $fontFamily={fonts.raleway}
                        className="text-nowrap"
                    >
                        {`${capitalizeFirstLetter(docs?.firstName) || "-"} ${docs?.lastName || "-"}`}
                    </StyledText>
                    {docs.unreadMessageCount > 0 ? (<div className='position-relative'>
                        <MessageIcon />

                        <UnreadCountCircle>
                            {docs?.unreadMessageCount > 5 ? "5+" : docs?.unreadMessageCount}
                        </UnreadCountCircle>


                    </div>) : null}
                </div>
            ),
        },
        {
            id: 2,
            path: "userType",
            label: (
                <StyledText as="label" $fontFamily={fonts.raleway} $fontWeight={600} $fontSize="13px" truncateWidth="140px">{t("admin:userTypeTableHeader")} </StyledText>
            ),
            alignBody: " align-middle text-center",
            alignHeader: "text-center align-middle",
            content: (docs, index) => (
                <div className="d-flex align-items-center justify-content-center gap-2">
                    <StyledText
                        $fontWeight={500}
                        $fontSize="13px"
                        color={theme.stormDust}
                        $fontFamily={fonts.quicksand}
                        className=" text-capitalize"
                    >
                        {docs?.userType?.toLowerCase() || "-"}
                    </StyledText>
                </div>
            ),
        },
        {
            id: 3,
            path: "userStatus",
            label: (
                <StyledText as="label" $fontFamily={fonts.raleway} $fontWeight={600} $fontSize="13px" truncateWidth="155px">{t("admin:userStatusTableHeader")} </StyledText>
            ),
            isChecked: true,
            alignBody: "align-middle",
            alignHeader: "align-middle text-center",
            content: (docs, index) => (
                <div className="d-flex align-items-center justify-content-center gap-2">
                    <StyledText
                        $fontWeight={600}
                        $fontSize="13px"
                        $fontFamily={fonts.quicksand}
                        color={
                            docs?.statusId === STATUSES?.LIVE
                                ? theme.flatGreen
                                : docs?.statusId === STATUSES?.PENDING && theme.jasperRed
                        }
                    >
                        {docs?.statusId === STATUSES?.LIVE
                            ? "Active"
                            : (docs?.statusId === STATUSES?.PENDING && "Pending") || "-"}
                    </StyledText>
                </div>
            ),
        },
        {
            id: 4,
            path: "conversationHistory",
            label: (<StyledText as="span" $fontFamily={fonts.quicksand} $fontWeight={600} $fontSize="13px">{t("admin:titleTblClientListCol2")}</StyledText>),
            isChecked: true,
            alignBody: "text-left align-middle",
            alignHeader: "text-left align-middle cursor-pointer",
            content: (docs) => (
                console.log(docs?.conversation[0]?.file?.fileName,'docs?.conversation[0]?.file?.fileName'),
                
                <div className="d-flex align-items-center  gap-2">

                    {docs?.conversation[0]?.message ? (
                        <StyledText
                            as="label"
                            className="conHistory"
                            $fontFamily={fonts.quicksand}
                            $fontWeight={500}
                            $fontSize="12px"
                            color={theme.gray}
                            truncatedlines={1}
                            truncateWidth="247px"
                            dangerouslySetInnerHTML={{ __html: docs?.conversation[0]?.message?.messageBody }}
                        />
                    ) : docs?.conversation[0]?.file && !docs?.conversation[0]?.message ? (
                        <FileAttachmentLable
                            fileName={docs?.conversation[0]?.file?.fileName}
                        />
                    ) : (
                        "-"
                    )}


                </div>
            ),
        },
        {
            id: 5,
            label: (<StyledText as="span" $fontFamily={fonts.raleway} $fontWeight={600} $fontSize="13px">{t("admin:titleTblActionCol")}</StyledText>),
            alignBody: "align-middle px-5",
            alignHeader: "align-middle text-center px-5",
            content: (docs) => (
                <div className="d-flex align-items-center justify-content-center gap-2">
                    <div
                        className="cursor-pointer text-muted bg-snowWhite p-2 rounded"
                        onClick={() => {
                            setaddClientModalOpen(true);
                            setIsEdit({ data: docs, state: true });
                        }}
                    >
                        <EditIcon size="18" color={theme.gray} />
                    </div>
                    <div
                        className="cursor-pointer text-muted bg-snowWhite p-2 rounded"
                        onClick={() => {
                            setDeleteData(true);
                            setUId(docs?.id);
                        }}
                    >
                        <DeleteIcon size="18" />
                    </div>
                    <div
                        className="cursor-pointer text-muted bg-snowWhite p-2 rounded"
                        onClick={() => {
                            window.open(`/admin-chat/${docs?.id}`, "_blank");
                            svenskaLs.setObject('userData', docs);
                            if (docs?.unreadMessageCount > 0) {
                                setTimeout(() => {
                                    refetchUsers();
                                }, 7000);
                            }

                        }}
                    >
                        <ViewIcon size="18" />
                    </div>
                </div>
            ),
        },
    ];

    return (
        <>
            <StyledContainer className='mt-4 p-0' height={clientDetails?.perPage > 5 && '273px'} zeroState={!loadClients && (clientDetails?.data?.length === 0 || clientDetails?.data?.length === undefined)}>
                {!loadClients && (clientDetails?.data?.length === 0 || clientDetails?.data?.length === undefined) ?

                    <div className="d-flex flex-column align-items-center justify-content-center h-100">
                        {/* <div className="d-flex align-items-center justify-content-center">
              <SharedDocZeroState />
            </div> */}
                        <StyledText
                            color={theme.gray}
                            $fontWeight={600}
                            $fontSize="20px"
                            className="text-center mt-3"
                        >
                            {t("admin:noClientsAvailable")}
                        </StyledText>
                        <StyledText
                            color={theme.gray}
                            $fontWeight={500}
                            $fontSize="14px"
                            className="text-center"
                        >
                            {t("admin:allClientsManage")}
                        </StyledText>
                    </div> : loadClients ? <StyledTableSkeleton cols={column} isFilter={true} /> : <CommonTable data={clientDetails?.data} column={column} />
                }

            </StyledContainer>
            {loadClients ? null : !loadClients && (clientDetails?.data?.length === 0 || clientDetails?.data?.length === undefined) ? null : <PaginationPerPage
                perPage={clientDetails?.perPage || 0}
                pageNo={clientDetails?.page || 0}
                onPerPageChange={handlePerPageChange}
                totalCount={clientDetails?.count || 0}
                onPageChange={handlePageChange}
            />}
        </>

    )
}




export default AdminClientTable