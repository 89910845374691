import React from 'react'
import PaginationPerPage from '../../../Components/pagination/PaginationPerPage'
import { useTranslation } from 'react-i18next';
import StyledImage from '../../../Components/StyledComponents/StyledImage';
import { capitalizeFirstLetter, DefaulImage, fonts, handleErrorImg, useWindowSize } from '../../../Services/Utils/helper';
import StyledText from '../../../Components/StyledComponents/StyledText';
import theme from '../../../Services/Utils/themes';
import DeleteIcon from '../../../Components/Svgicons/DeleteIcon';
import { StyledContainer } from '../../../Components/StyledComponents/CommonStyled';
import ViewIcon from '../../../Components/Svgicons/ViewIcon';
import CommonTable from '../CommonTable';
import { useNavigate } from 'react-router-dom';
import StyledTableSkeleton from '../../../Components/StyledComponents/StyledTableSkeleton';

const AdminTable = ({ adminDetail, handlePerPageChange, handlePageChange, setaddAdminModalOpen, setIsEditAdmin, setDeleteData, setAdminId, loadAdmins }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const column = [
        {
            id: 1,
            path: "title",
            label: (
                <StyledText as="label" $fontFamily={fonts.raleway} $fontWeight={600} $fontSize="13px" color={theme.secondary} truncateWidth="186px">{t("admin:titleTblAdminListCol1")} </StyledText>
            ),
            alignBody: "align-middle px-3 px-md-5 py-3",
            alignHeader: "text-left align-middle cursor-pointer px-3 px-md-5 py-3 col-2",
            content: (docs, index) => {
                return (
                    <div className="d-flex align-items-center gap-2">
                        <div>
                            <StyledImage
                                height={"40px"}
                                width={"40px"}
                                className=" rounded-circle"
                            >
                                <img
                                    src={docs?.signedUrl || DefaulImage.image}
                                    alt="img"
                                    onError={handleErrorImg}
                                    style={{objectFit: "cover"}}
                                />
                            </StyledImage>
                        </div>
                        <StyledText
                            $fontWeight={600}
                            $fontSize="13px"
                            $fontFamily={fonts.raleway}
                            color={theme.secondary}
                            className="text-nowrap"
                        >
                            {`${capitalizeFirstLetter(docs?.firstName)} ${docs?.lastName}`}
                        </StyledText>
                    </div>
                );
            },
        },
        {
            id: 2,
            path: "personalNumber",
            label: (<StyledText as="label" $fontFamily={fonts.raleway} $fontWeight={600} $fontSize="13px" color={theme.secondary} truncateWidth="150px">{t("admin:titleTblAdminListCol2")}</StyledText>),
            isChecked: true,
            alignBody: "align-middle",
            alignHeader: "text-center align-middle px-3 py-3 col-8",
            content: (docs) => (
                <div className="d-flex align-items-center justify-content-center gap-2">
                    {(docs?.conversationHistory !== null && docs?.file === null) ||
                        (docs?.conversationHistory !== null && docs?.file !== null) ? (
                        <div>
                            <StyledText
                                as="label"
                                className="conHistory"
                                $fontWeight={500}
                                $fontFamily={fonts.quicksand}
                                $fontSize="13px"
                                color={theme.gray}
                            >
                                {docs?.personalNumber}
                            </StyledText>
                        </div>
                    ) : null}
                </div>
            ),
        },
        {
            id: 3,
            label: (<StyledText as="span" $fontFamily={fonts.raleway} $fontWeight={600} $fontSize="13px" color={theme.secondary}>{t("admin:titleTblActionCol")}</StyledText>),
            isChecked: true,
            alignBody: "align-middle",
            alignHeader: "align-middle text-center px-3 py-3 col-2",
            content: (docs) => (
                <div className="d-flex align-items-center justify-content-center gap-2">

                    <div
                        className="cursor-pointer text-muted bg-light-gray p-2 rounded"
                        onClick={() => {
                            setDeleteData(true);
                            setAdminId(docs?.id);
                        }}
                    >
                        <DeleteIcon size="18" className="mb-1" />
                    </div>
                    <div
                        className="cursor-pointer text-muted bg-light-gray p-2 rounded"
                        onClick={() => {
                            docs?.id && navigate(`/single-admin/${docs?.id}`);
                        }}
                    >
                        <ViewIcon size="20" className="mb-1" />
                    </div>
                </div>
            ),
        },
    ];

    return (
        <>
            <StyledContainer className='mt-4 p-0' height={adminDetail?.perPage && '273px'} zeroState={adminDetail?.data?.length === 0 || adminDetail?.data?.length === undefined}>
                {!loadAdmins && (adminDetail?.data?.length === 0 || adminDetail?.data?.length === undefined) ?
                    <div className="d-flex flex-column align-items-center justify-content-center h-100">
                        {/* <div className="d-flex align-items-center justify-content-center">
     <SharedDocZeroState />
   </div> */}
                        <StyledText
                            color={theme.gray}
                            $fontWeight={600}
                            $fontSize="20px"
                            className="text-center mt-3"
                        >
                            {t("admin:noAdminsAvailable")}
                        </StyledText>
                        <StyledText
                            color={theme.gray}
                            $fontWeight={500}
                            $fontSize="14px"
                            className="text-center"
                        >
                            {t('admin:allAdminsManage')}
                        </StyledText>
                    </div> : loadAdmins ? <StyledTableSkeleton cols={column} /> :

                        <CommonTable data={adminDetail?.data} column={column} />
                        }
            </StyledContainer>
            {loadAdmins ?  null : !loadAdmins && (adminDetail?.data?.length === 0 || adminDetail?.data?.length === undefined) ? null :  <PaginationPerPage
                perPage={adminDetail?.perPage || 0}
                pageNo={adminDetail?.page || 0}
                onPerPageChange={handlePerPageChange}
                totalCount={adminDetail?.count || 0}
                onPageChange={handlePageChange}
            />}
        </>

    )
}

export default AdminTable